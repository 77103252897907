import {
  Button,
  Card,
  Tabs,
  Checkbox,
  message,
  Select,
  Steps,
  DatePicker,
} from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React, { useState, useEffect } from "react";
import { IoFastFood, IoNewspaperOutline } from "react-icons/io5";
import { RiCalendarTodoFill } from "react-icons/ri";
import moment from "moment";
import BillDetails from "./BillDetails";
import axios from "axios";

const { RangePicker } = DatePicker;
const { Step } = Steps;

function BookingMobile() {
  const [details, setDetails] = useState({
    room_count: null,
    villaAvailable: null,
    villa_weekdayRate: null,
    room_weekdayRate: null,
    room_weekendRate: null,
    villa_weekendRate: null,
  });
  const [current, setCurrent] = useState(0);
  const [optionList, setOptionList] = useState([]);
  const [booking, setbooking] = useState({
    check_in: null,
    check_out: null,
    range: null,
    isVilla: false,
    termsAndConditions: false,
    no_of_rooms: 1,
    num_of_adults: 0,
    num_of_child: 0,
    veg: 0,
    jain: 0,
    non_veg: 0,
    room_type: "Deluxe Room",
  });
  const [customer, setcustomer] = useState({
    name: null,
    phoneNumber: null,
    emailID: null,
    resAddress: null,
  });
  const [roomList, setRoomList] = useState([
    { roomType: "Deluxe Room", numberOfAdults: null, numberOfKids: null },
  ]);
  const [bookingClicked, setBookingClicked] = useState(false);

  const {
    check_in,
    check_out,
    range,
    isVilla: villaNeeded,
    termsAndConditions,
    no_of_rooms,
    num_of_adults: noOfAdults,
    num_of_child: noOfKids,
    veg: vegiterians,
    non_veg: nonvegiterians,
    jain: jainterians,
  } = booking;
  const updateOptionList = () => {
    const temp = [];
    if (noOfAdults || noOfKids) {
      for (let i = 0; i < noOfAdults + noOfKids; i++) {
        temp.push(
          <Select.Option key={i} value={i + 1}>
            {i + 1}
          </Select.Option>
        );
      }
      setOptionList(temp);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onChangeDate = (e) => {
    setbooking({ ...booking, range: e });
  };

  useEffect(() => {
    updateOptionList();
    if (check_in !== null && check_out !== null && bookingClicked) {
        setBookingClicked(false);
        next();
      }
    //eslint-disable-next-line
  }, [booking]);
  const handleSelect = (e, index, type) => {
    if (e === "The Villa") {
      setbooking({
        ...booking,
        villaNeeded: true,
        no_of_rooms: no_of_rooms - 1,
      });
    }
    const list = [...roomList];
    if (list[index] != null) {
      if (list[index]["roomType"] === "The Villa") {
        setbooking({
          ...booking,
          villaNeeded: false,
          no_of_rooms: no_of_rooms + 1,
        });
      }
      list[index][type] = e;
      setRoomList(list);
      if (type === "numberOfAdults") {
        var noa = 0;
        for (let i = 0; i < list.length; i++) {
          noa += list[i]["numberOfAdults"];
        }
        setbooking({ ...booking, num_of_adults: noa });
      } else if (type === "numberOfKids") {
        var nok = 0;
        for (let i = 0; i < list.length; i++) {
          nok += list[i]["numberOfKids"];
        }
        setbooking({ ...booking, num_of_child: nok });
      }
    }
  };
  const onSubmitCustomer = (e) => {
    setcustomer(e);
    setCurrent(2);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else if (action === "remove") {
      remove(targetKey);
    }
  };
  const add = () => {
    if (roomList.length > 12 && villaNeeded) {
      message.error("Cannot add another room.");
    } else if (roomList.length > 12 && !villaNeeded) {
      setRoomList([
        ...roomList,
        { roomType: "The Villa", numberOfAdults: null, numberOfKids: null },
      ]);
      setbooking({ ...booking, villaNeeded: true });
    } else {
      setRoomList([
        ...roomList,
        { roomType: "Deluxe Room", numberOfAdults: null, numberOfKids: null },
      ]);
      setbooking({ ...booking, no_of_rooms: no_of_rooms + 1 });
    }
  };
  const remove = (targetKey) => {
    const list = [...roomList];
    setbooking({
      ...booking,
      num_of_adults: noOfAdults - list[targetKey]["numberOfAdults"],
      num_of_child: noOfKids - list[targetKey]["numberOfKids"],
      no_of_rooms: no_of_rooms - 1,
    });
    list.splice(targetKey, 1);
    setRoomList(list);
  };
  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };
  const handleMealSelect = (e, type) => {
    if (type === "Veg") {
      setbooking({ ...booking, veg: e });
    } else if (type === "Jain") {
      setbooking({ ...booking, jain: e });
    } else if (type === "Non Veg") {
      setbooking({ ...booking, non_veg: e });
    }
  };

  const onChange = (e) => {
    if (e.target.name !== "termsAndConditions")
      setbooking({ ...booking, [e.target.name]: e.target.value });
    else setbooking({ ...booking, [e.target.name]: e.target.checked });
  };
  const onClickBook = (e) => {
    //TODO: Need to fix state setting late for dates
    if (
      e !== null &&
      termsAndConditions !== false &&
      noOfAdults !== 0 &&
      roomList.length !== 0 &&
      vegiterians + jainterians + nonvegiterians === noOfAdults + noOfKids &&
      e[1].diff(e[0], "days") !== 0
    ) {
      if (e[0] !== null && e[1] !== null) {
        let date = e[0]._d;
        var in_dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        let date1 = e[1]._d;
        var out_dateString = new Date(
          date1.getTime() - date1.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        if (villaNeeded)
          setbooking({
            ...booking,
            check_in: in_dateString,
            check_out: out_dateString,
            no_of_rooms: roomList.length - 1,
          });
        else
          setbooking({
            ...booking,
            check_in: in_dateString,
            check_out: out_dateString,
            no_of_rooms: roomList.length,
          });
      }
      setBookingClicked(true);
    } else if (termsAndConditions !== true) {
      message.error("Please read the terms and conditions!");
    } else if (roomList.length === 0) {
      message.error("Please Add Rooms to book.");
    } else if (noOfAdults === 0) {
      message.error("Please Enter the number of people.");
    } else if (
      vegiterians + jainterians + nonvegiterians !==
      noOfAdults + noOfKids
    ) {
      message.error("Please Enter the right amount of meal amount.");
    } else if (e !== null) {
      if (e[1].diff(e[0], "days") === 0) {
        message.error(
          "Please Enter different dates. You have to stay one night minimum."
        );
      }
    } else {
      message.error("Please enter the dates.");
    }
  };
  const next = async () => {
    try {
      const response = await axios.post(
        "https://${process.env.REACT_APP_DJANGO_URL}/room/available/",
        {
          booking: {
            check_in: check_in,
            check_out: check_out,
            room_type: "Deluxe Room",
            isVilla: villaNeeded,
          },
        }
      );
      setDetails(response.data);
      if (response.data["room_count"] >= no_of_rooms) {
        if (response.data["villaAvailable"] === villaNeeded) {
          console.log("Available");
          setCurrent(current + 1);
        } else if (
          response.data["villaAvailable"] === true &&
          villaNeeded === false
        ) {
          console.log("Available");
          setCurrent(current + 1);
        } else {
          message.error("Villa not available");
        }
      } else {
        message.error("Not available");
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Layout>
      <Content>
        <Steps style={{ margin: "1% 5%" }} current={current}>
          <Step key="booking" title="Check Availability" />
          <Step key="bill" title="Billing Information" />
          <Step key="payment" title="Payment" />
        </Steps>
        {current === 0 && (
          <>
            <Card
              style={{
                margin: "1% 5%",
                boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)",
              }}
            >
              <h3 style={{ fontSize: "1.7em", marginBottom: ".1em" }}>
                {" "}
                {
                  <RiCalendarTodoFill
                    style={{ color: "#169120" }}
                  ></RiCalendarTodoFill>
                }{" "}
                Booking Details
                <b style={{ color: "red", fontSize: "20px" }}> *</b>
              </h3>
              <div
                style={{
                  height: "2px",
                  backgroundColor: "#169120",
                  width: "15%",
                  marginBottom: "2%",
                }}
              />
              <RangePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                size={"large"}
                onChange={onChangeDate}
                placeholder={["Check In", "Check Out"]}
                separator="to "
                value={range}
              ></RangePicker>
              <br />
              <Tabs type="editable-card" onEdit={onEdit}>
                {roomList !== null ? (
                  <>
                    {roomList.map((room, index) => (
                      <Tabs.TabPane key={index} tab={index + 1}>
                        <h1>{room.roomType}</h1>
                        <h3>Room Type:</h3>
                        <Select
                          style={{ width: "70%", margin: "0 0 1% 0 " }}
                          value={room.roomType}
                          placeholder="Select Room Type"
                          onSelect={(e) => {
                            handleSelect(e, index, "roomType");
                          }}
                        >
                          <Select.Option value="Deluxe Room">
                            Deluxe Room
                          </Select.Option>
                          {villaNeeded === false && (
                            <Select.Option value="The Villa">
                              The Villa
                            </Select.Option>
                          )}
                        </Select>
                        <h3>Number of Adults:</h3>
                        <Select
                          name="numberOfAdults"
                          style={{ width: "70%", margin: "0 0 1% 0 " }}
                          value={room.numberOfAdults}
                          placeholder="Number of Adults"
                          onSelect={(e) => {
                            handleSelect(e, index, "numberOfAdults");
                          }}
                        >
                          {room.roomType === "The Villa" ? (
                            <>
                              <Select.Option value={8}>8</Select.Option>
                              <Select.Option value={9}>9</Select.Option>
                              <Select.Option value={10}>10</Select.Option>
                              <Select.Option value={11}>11</Select.Option>
                              <Select.Option value={12}>12</Select.Option>
                            </>
                          ) : (
                            <>
                              <Select.Option value={1}>1</Select.Option>
                              <Select.Option value={2}>2</Select.Option>
                            </>
                          )}
                        </Select>
                        <h3>Number of Kids:</h3>
                        {room.roomType === "Deluxe Room" &&
                        room.numberOfAdults === "3" ? (
                          <Select
                            disabled
                            style={{ width: "70%" }}
                            placeholder="Number of Kids"
                          ></Select>
                        ) : (
                          <Select
                            name="numberOfKids"
                            value={room.numberOfKids}
                            style={{ width: "70%", margin: "0 0 1% 0 " }}
                            placeholder="Number of Kids"
                            onSelect={(e) => {
                              handleSelect(e, index, "numberOfKids");
                            }}
                          >
                            {room.roomType === "The Villa" ? (
                              <>
                                <Select.Option value={0}>0</Select.Option>
                                <Select.Option value={1}>1</Select.Option>
                                <Select.Option value={2}>2</Select.Option>
                                <Select.Option value={3}>3</Select.Option>
                                <Select.Option value={4}>4</Select.Option>
                              </>
                            ) : (
                              <>
                                <Select.Option value={0}>0</Select.Option>
                                <Select.Option value={1}>1</Select.Option>
                              </>
                            )}
                          </Select>
                        )}
                      </Tabs.TabPane>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Tabs>
            </Card>
            <Card
              style={{
                margin: "1% 5%",
                boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)",
              }}
            >
              <h3 style={{ fontSize: "1.7em", marginBottom: ".1em" }}>
                {" "}
                {<IoFastFood style={{ color: "#169120" }}></IoFastFood>}Meal
                Plan<b style={{ color: "red", fontSize: "20px" }}> *</b>
              </h3>
              <div
                style={{
                  height: "2px",
                  backgroundColor: "#169120",
                  width: "15%",
                  marginBottom: "2%",
                }}
              />
              <Select
                style={{ width: "100%", margin: "2% 1%" }}
                placeholder="Number of Vegeterians"
                onSelect={(e) => {
                  handleMealSelect(e, "Veg");
                }}
              >
                {optionList.map((item) => item)}
              </Select>
              <Select
                style={{ width: "100%", margin: "2% 1%" }}
                placeholder="Number of Jain Vegeterians"
                onSelect={(e) => {
                  handleMealSelect(e, "Jain");
                }}
              >
                {optionList.map((item) => item)}
              </Select>
              <Select
                style={{ width: "100%", margin: "2% 1%" }}
                placeholder="Number of Non-Vegeterians"
                onSelect={(e) => {
                  handleMealSelect(e, "Non Veg");
                }}
              >
                {optionList.map((item) => item)}
              </Select>
              {vegiterians + jainterians + nonvegiterians !==
                noOfAdults + noOfKids && (
                <p>
                  <b>Please Enter the right number of people here.</b>
                </p>
              )}
            </Card>
            <Card
              style={{
                margin: "1% 5%",
                boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)",
              }}
            >
              <h3 style={{ fontSize: "1.7em", marginBottom: ".1em" }}>
                {" "}
                {
                  <IoNewspaperOutline
                    style={{ color: "#169120" }}
                  ></IoNewspaperOutline>
                }
                Terms and Conditions
                <b style={{ color: "red", fontSize: "20px" }}> *</b>
              </h3>
              <div
                style={{
                  height: "2px",
                  backgroundColor: "#169120",
                  width: "15%",
                  marginBottom: "2%",
                }}
              />
            </Card>
            <Checkbox
              name="termsAndConditions"
              style={{ margin: "1%" }}
              checked={termsAndConditions}
              onChange={onChange}
            >
              I have read the Terms and Conditions and Accept them.
            </Checkbox>
            <Button
              type="primary"
              size="large"
              style={{ width: "94%", margin: "5% 3% 5% 3%" }}
              onClick={() => onClickBook(range)}
            >
              Check if available
            </Button>
          </>
        )}
        {current === 1 && (
          <>
            <BillDetails
              details={details}
              booking={booking}
              customer={customer}
              prev={prev}
              onSubmit={onSubmitCustomer}
            />
          </>
        )}
      </Content>
    </Layout>
  );
}

export default BookingMobile;

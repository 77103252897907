import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import "./Contact.css";
import { SiAirbnb } from "react-icons/si";
import {
  IoLogoFacebook,
  IoLogoWhatsapp,
  IoLogoInstagram,
} from "react-icons/io5";
import Fade from "react-reveal/Fade";
import Layout, { Content } from "antd/lib/layout/layout";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //TODO: Contact Page
  return (
    <Fade>
      <Layout>
        <Content>
          <Row style={{ height: 330 }} className="about">
            <Col span={3} />
            <Col span={6}>
              <h2
                style={{
                  marginTop: "40% ",
                  marginLeft: "15%",
                  color: "white",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Contact Us
              </h2>
            </Col>
          </Row>
          <Row
            style={{
              height: "60   .3vh",
              paddingTop: "3%",
              paddingBottom: "2%",
            }}
          >
            <Col span={4} />
            <Col span={16}>
              <Row>
                <Col span={12}>
                  <img
                    loading="lazy"
                    src="booking.svg"
                    alt="svg booking"
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col span={12}>
                  <br />
                  <h2 style={{ width: "100%", textAlign: "center" }}>
                    Contact Us
                  </h2>
                  <Row>
                    <Button
                      type="primary"
                      style={{ margin: "auto", width: "90%" }}
                      size="large"
                    >
                      <p style={{ fontSize: "20px" }}>
                        <IoLogoWhatsapp />
                        <a
                          style={{ color: "white" }}
                          href="https://wa.me/+919175234850"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {" "}
                          Connect on Whatsapp
                        </a>
                      </p>
                    </Button>
                  </Row>
                  <br />
                  <Row>
                    <Button
                      type="primary"
                      style={{
                        margin: "auto",
                        width: "90%",
                        backgroundColor: "#4267b2",
                        border: "0px",
                      }}
                      size="large"
                    >
                      <p style={{ fontSize: "20px" }}>
                        <IoLogoFacebook />
                        <a
                          style={{ color: "white" }}
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.facebook.com/people/The-Green-Estate-Resort/61556199878551/"
                        >
                          {" "}
                          Connect on Facebook
                        </a>
                      </p>
                    </Button>
                  </Row>
                  <br />
                  <Row>
                    <Button
                      type="primary"
                      style={{
                        margin: "auto",
                        width: "90%",
                        backgroundColor: "#dd2a7b",
                        border: "0px",
                      }}
                      size="large"
                    >
                      <p style={{ fontSize: "20px" }}>
                        <a
                          style={{ color: "#ffffff", textDecoration: "none" }}
                          href="https://www.instagram.com/the_green_estate_resort/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <IoLogoInstagram />
                          Connect on Instagram
                        </a>
                      </p>
                    </Button>
                  </Row>
                  <br />
                </Col>
              </Row>
            </Col>
            <Col span={4} />
          </Row>
        </Content>
      </Layout>
    </Fade>
  );
}

export default Contact;

import { Card, Row, Col, Button } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { UserOutlined } from "@ant-design/icons";
import Layout, { Content } from "antd/lib/layout/layout";
import React,{useEffect} from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import "./HomePage.css";
function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const headingStyle = {
    padding: 10,
    fontSize: "95px",
    color: "white",
  };
  const contentStyle = {
    padding: 20,
    position: "absolute",
    top: "30%",
  };
  return (
    <Layout>
      <Content>
        <Row style={{ height: 910 }} className="parallax">
          <Col lg={4} xs={0} />
          <Col lg={16} xs={24}>
            <Row style={contentStyle}>
              <div>
                <Fade left>
                  <p style={headingStyle}>
                    <b>
                      <p style={{ fontSize: "35px", marginBottom: "0" }}>
                        Welcome to
                      </p>
                      <p style={{ marginBottom: "0" }}>The Green Estate Resort</p>
                      <p style={{ fontSize: "35px", marginBottom: "0" }}>by Ajay Murlidhar Somvanshi (HUF)</p>
                    </b>
                  </p>
                </Fade>
              </div>
            </Row>
          </Col>
          <Col lg={4} xs={0} />
        </Row>
        <Row style={{ backgroundColor: "white" }}>
          <Col span={4} />
          <Col span={16}>
            <Fade bottom>
              <Row>
                <Col lg={12} xs={24}>
                  <div>
                    <h2
                      style={{
                        margin: "10% 10% auto",
                        width: "90%",
                        fontSize: "240%",
                      }}
                    >
                      Plan your next stay with us!
                    </h2>
                    <h3 style={{ margin: "0 12% auto", width: "90%" }}>
                      The Green Estate Resort located in the outskirts of Nashik at
                      village Matori, Nestled in green surroundings farmhouse is
                      surrounded by the attractive and panoramic natural scenes
                      like green farmyards & picturesque mountains, In addition
                      to such fun experiences. you can celebrate your family
                      function with great delectation and comfort here at this
                      well-equipped resort.
                    </h3>
                    <br></br>
                    {isDesktopOrLaptop ? (
                      <>
                        <Button
                          size="large"
                          shape="round"
                          style={{
                            color: "black",
                            margin: "0% 2% 12% 12% ",
                            width: "20%",
                          }}
                        >
                          <Link to="/contact">Contact Us</Link>
                        </Button>
                        <i>or</i> <Link to="/about">&nbsp;LEARN MORE</Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
                <Col lg={12} xs={0} sm={0}>
                  <img loading='lazy' 
                    src="/HomePage/image1.jpg"
                    alt="first"
                    style={{ borderRadius: "25px", margin: "10% 10% 10%" }}
                    width="90%"
                  ></img>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col span={4} />
        </Row>
        <Row>
          <Col span={4} />
          <Col span={16}>
            <Fade bottom>
              <h2
                style={{
                  margin: "3% 0% auto",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Rooms and Villa
              </h2>
              <h3
                style={{
                  fontSize: "25px",
                  margin: "0% 0% 5% auto",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Stay at our 5-bedroom Villa or Book a Deluxe Room surrounded by
                the attractive and panoramic <br></br>natural scenes like
                rolling and green farmyard, picturesque.
              </h3>
            </Fade>
            <Fade bottom>
              <Row gutter={24} style={{ margin: "0 0 5% 0" }}>
                <Col lg={11} sm={12} md={12}>
                  <img loading='lazy' 
                    src="image1.jpeg"
                    alt="villa"
                    style={{
                      borderRadius: "25px",
                      margin: "auto",
                      width: "100%",
                    }}
                  ></img>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "40px",
                    }}
                  >
                    The Villa
                  </h1>
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "#149600",
                    }}
                  >
                    5-Bedroom Villa with a private pool and a large living room
                    for all the activites.
                  </p>
                </Col>
                <Col lg={2} sm={0} md={0}></Col>
                <Col lg={11} sm={12} md={12}>
                  <img loading='lazy' 
                    src="/HomePage/image3.jpg"
                    alt="room"
                    style={{
                      borderRadius: "25px",
                      margin: "auto",
                      width: "100%",
                    }}
                  ></img>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "40px",
                    }}
                  >
                    Deluxe Rooms
                  </h1>
                  <p
                    style={{
                      fontSize: "25px",
                      textAlign: "center",
                      color: "#149600",
                    }}
                  >
                    Deluxe A/C Room with a common swimming pool
                  </p>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col span={4} />
        </Row>
        <Row className="parallaxSecond" style={{ height: 400 }}>
          <Col span={4} />
          <Col span={16}>
            <Fade bottom>
              <div>
                <h2
                  style={{
                    color: "white",
                    margin: "7% 0% auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Enjoy your stay with us,<br></br>
                  <span style={{ fontSize: "25px" }}>
                    with your loved ones in the love of mother nature.
                  </span>
                </h2>
              </div>
            </Fade>
          </Col>
          <Col span={4} />
        </Row>
        <Row style={{ backgroundColor: "white", paddingBottom: "4%" }}>
          <Fade bottom>
            <h2
              style={{
                fontSize: "50px",
                fontWeight: "lighter",
                margin: "3% 0% auto",
                width: "100%",
                textAlign: "center",
              }}
            >
              Recent Reviews
            </h2>
          </Fade>
          <Col lg={2} xs={0} />
          <Col lg={6} xs={24}>
            <Fade bottom>
              <Card hoverable style={{ minHeight: 508 }}>
                <div
                  style={{
                    margin: "3% 0% auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "25px",
                      fontWeight: "lighter",
                      marginTop: "5%",
                    }}
                  >
                    Its an excellent property not very far from the city, but at
                    the same time has the taste of a village life. The rooms are
                    spacious to accomodate three people. The food is delicious.
                    Very nice swimming pool. Lots of extra activities.
                  </h3>
                  <h3 style={{ color: "grey" }}>-Ranjit Nair</h3>
                </div>
              </Card>
            </Fade>
          </Col>
          <Col lg={1} xs={0} />
          <Col lg={6} xs={24}>
            <Fade bottom>
              <Card hoverable style={{ minHeight: 508 }}>
                <div
                  style={{
                    margin: "3% 0% auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "25px",
                      fontWeight: "lighter",
                      marginTop: "5%",
                    }}
                  >
                    Beautiful, aesthetically pleasing residences. Courteous host
                    and staff. Very homely ambiance. Peaceful place. Delicious
                    food served with love. The place to go and relax. Guests are
                    very well taken care of. Thanks a lot to Mr Ajay Somwanshi
                    and his son Rushil for allowing us to check in late in the
                    night.
                  </h3>
                  <h3 style={{ color: "grey" }}>-Meenakshi Joshi</h3>
                </div>
              </Card>
            </Fade>
          </Col>
          <Col lg={1} xs={0} />
          <Col lg={6} xs={24}>
            <Fade bottom>
              <Card hoverable style={{ minHeight: 508 }}>
                <div
                  style={{
                    margin: "3% 0% auto",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "25px",
                      fontWeight: "lighter",
                      marginTop: "5%",
                    }}
                  >
                    Best weekend I have ever had in my life. Amazing ambience,
                    friendly staff, caring people, would call it like a home
                    stay. Owners are like family members. Farm life experience
                    with animals, trees fresh fruits and veggie around. Swing
                    cricket cycling. Room overlooking vine yards and mountains.
                    Village life in city with modern amenities. Must visit
                  </h3>
                  <h3 style={{ color: "grey" }}>-Amrita Shah</h3>
                </div>
              </Card>
            </Fade>
          </Col>
          <Col lg={2} xs={0} />
        </Row>
        <Row style={{ paddingBottom: "5%" }}>
          <Col lg={5} md={3} sm={1} xs={1} />
          <Col lg={14} md={18} sm={22} xs={22}>
            <Fade bottom>
              <h2
                style={{
                  margin: "7% 0% auto",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Photo Gallery
              </h2>
            </Fade>
            <Fade bottom>
              <Slider dots={true} autoplay={true} autoplaySpeed={1600}>
                <img loading='lazy' 
                  src="/HomePage/Carousel/bedroom2.jpg"
                  alt="room"
                  style={{ borderRadius: "25px" }}
                ></img>
                <img loading='lazy' 
                  src="/HomePage/Carousel/dining2.jpg"
                  alt="room"
                  style={{ borderRadius: "25px" }}
                ></img>
                <img loading='lazy' 
                  src="/HomePage/Carousel/space3.jpg"
                  alt="room"
                  style={{ borderRadius: "25px" }}
                ></img>
              </Slider>
            </Fade>
          </Col>
          <Col lg={5} md={3} sm={1} xs={1} />
        </Row>
        <Row className="parallaxThird" style={{ height: 300 }}>
          <Col lg={4} xs={0} />
          <Col lg={8} xs={24}>
            <Fade bottom>
              <div>
                <h2
                  style={{
                    fontSize: "37px",
                    color: "white",
                    margin: "10% 0% auto",
                    width: "100%",
                  }}
                >
                  A amazing experience awaits you! <br></br>Book now!
                </h2>
              </div>
            </Fade>
          </Col>
          <Col lg={8} xs={24}>
            {isTabletOrMobile ? (
              <Button
                size="large"
                type="primary"
                shape="round"
                style={{ width: "100%" }}
              >
                <Link to="/contact">Contact Us</Link>
              </Button>
            ) : (
              <Button
                size="large"
                type="primary"
                shape="round"
                style={{ margin: "17% 0% 10% 50%", width: "40%" }}
              >
                <Link to="/contact">Contact Us</Link>
              </Button>
            )}
          </Col>
          <Col lg={4} xs={0} />
        </Row>
      </Content>
    </Layout>
  );
}

export default HomePage;

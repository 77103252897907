import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Steps,
  Card,
  Row,
  Checkbox,
  message,
  Select,
  Divider,
  Col,
} from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { IoFastFood, IoNewspaperOutline } from "react-icons/io5";
import { RiCalendarTodoFill } from "react-icons/ri";
import { PlusSquareOutlined } from "@ant-design/icons";
import moment from "moment";
import Fade from "react-reveal/Fade";
import BillDetails from "./BillDetails";
import axios from "axios";

const { RangePicker } = DatePicker;
const { Step } = Steps;

function Booking() {
  const [details, setDetails] = useState({
    room_count: null,
    villaAvailable: null,
    villa_weekdayRate: null,
    room_weekdayRate: null,
    room_weekendRate: null,
    villa_weekendRate: null,
  });
  const [current, setCurrent] = useState(0);
  const [customer, setcustomer] = useState({
    name: null,
    phoneNumber: null,
    emailID: null,
    resAddress: null,
  });
  const [roomList, setRoomList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [booking, setbooking] = useState({
    check_in: null,
    check_out: null,
    isVilla: false,
    no_of_rooms: 0,
    num_of_adults: 0,
    num_of_child: 0,
    veg: 0,
    jain: 0,
    non_veg: 0,
    room_type: "Deluxe Room",
    range: null,
    termsAndConditions: false,
  });
  const [bookingClicked, setBookingClicked] = useState(false);
  const {
    check_in,
    check_out,
    range,
    isVilla: villaNeeded,
    termsAndConditions,
    no_of_rooms,
    num_of_adults: noOfAdults,
    num_of_child: noOfKids,
    veg: vegiterians,
    non_veg: nonvegiterians,
    jain: jainterians,
  } = booking;
  const updateOptionList = () => {
    const temp = [];
    if (noOfAdults || noOfKids) {
      for (let i = 0; i < noOfAdults + noOfKids; i++) {
        temp.push(
          <Select.Option key={i} value={i + 1}>
            {i + 1}
          </Select.Option>
        );
      }
      setOptionList(temp);
    }
  };

  useEffect(() => {
    updateOptionList();
    if (check_in !== null && check_out !== null && bookingClicked) {
      setBookingClicked(false);
      next();
    }
    //eslint-disable-next-line
  }, [booking]);
  const onSubmitCustomer = (e) => {
    setcustomer(e);
    setCurrent(3);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const onChangeDate = (e) => {
    setbooking({ ...booking, range: e });
  };
  const onChange = (e) => {
    if (e.target.name !== "termsAndConditions")
      setbooking({ ...booking, [e.target.name]: e.target.value });
    else setbooking({ ...booking, [e.target.name]: e.target.checked });
  };
  const handleAddRoom = () => {
    if (roomList.length > 12 && villaNeeded) {
      message.error("Cannot add another room.");
    } else if (roomList.length > 12 && !villaNeeded) {
      setRoomList([
        ...roomList,
        { roomType: "The Villa", numberOfAdults: null, numberOfKids: null },
      ]);
      setbooking({ ...booking, isVilla: true });
    } else {
      setRoomList([
        ...roomList,
        { roomType: "Deluxe Room", numberOfAdults: null, numberOfKids: null },
      ]);
      setbooking({ ...booking, no_of_rooms: no_of_rooms + 1 });
    }
  };
  const handleDeleteRoom = (index) => {
    const list = [...roomList];
    setbooking({
      ...booking,
      num_of_adults: noOfAdults - list[index]["numberOfAdults"],
      num_of_child: noOfKids - list[index]["numberOfKids"],
      no_of_rooms: no_of_rooms - 1,
    });
    list.splice(index, 1);
    setRoomList(list);
  };
  const handleSelect = (e, index, type) => {
    if (e === "The Villa") {
      setbooking({ ...booking, isVilla: true, no_of_rooms: no_of_rooms - 1 });
    }
    const list = [...roomList];
    if (list[index] != null) {
      if (list[index]["roomType"] === "The Villa") {
        setbooking({
          ...booking,
          isVilla: false,
          no_of_rooms: no_of_rooms + 1,
        });
      }
      list[index][type] = e;
      setRoomList(list);
      if (type === "numberOfAdults") {
        var noa = 0;
        for (let i = 0; i < list.length; i++) {
          noa += list[i]["numberOfAdults"];
        }
        setbooking({ ...booking, num_of_adults: noa });
      } else if (type === "numberOfKids") {
        var nok = 0;
        for (let i = 0; i < list.length; i++) {
          nok += list[i]["numberOfKids"];
        }
        setbooking({ ...booking, num_of_child: nok });
      }
    }
  };
  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };
  const handleMealSelect = (e, type) => {
    if (type === "Veg") {
      setbooking({ ...booking, veg: e });
    } else if (type === "Jain") {
      setbooking({ ...booking, jain: e });
    } else if (type === "Non Veg") {
      setbooking({ ...booking, non_veg: e });
    }
  };
  const onClickBook = (e) => {
    if (
      e !== null &&
      termsAndConditions !== false &&
      noOfAdults !== 0 &&
      roomList.length !== 0 &&
      vegiterians + jainterians + nonvegiterians === noOfAdults + noOfKids &&
      e[1].diff(e[0], "days") !== 0
    ) {
      if (e[0] !== null && e[1] !== null) {
        let date = e[0]._d;
        var in_dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        let date1 = e[1]._d;
        var out_dateString = new Date(
          date1.getTime() - date1.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        if (villaNeeded)
          setbooking({
            ...booking,
            check_in: in_dateString,
            check_out: out_dateString,
            no_of_rooms: roomList.length - 1,
          });
        else
          setbooking({
            ...booking,
            check_in: in_dateString,
            check_out: out_dateString,
            no_of_rooms: roomList.length,
          });
      }
      setBookingClicked(true);
    } else if (termsAndConditions !== true) {
      message.error("Please read the terms and conditions!");
    } else if (roomList.length === 0) {
      message.error("Please Add Rooms to book.");
    } else if (noOfAdults === 0) {
      message.error("Please Enter the number of people.");
    } else if (
      vegiterians + jainterians + nonvegiterians !==
      noOfAdults + noOfKids
    ) {
      message.error("Please Enter the right amount of meal amount.");
    } else if (e !== null) {
      if (e[1].diff(e[0], "days") === 0) {
        message.error(
          "Please Enter different dates. You have to stay one night minimum."
        );
      }
    } else {
      message.error("Please enter the dates.");
    }
  };
  const next = async () => {

    try { 
      const response = await axios.post(
        `https://${process.env.REACT_APP_DJANGO_URL}/room/available/`,
        {
          check_in: check_in,
          check_out: check_out,
          room_type: "Deluxe Room",
          isVilla: villaNeeded,
          no_of_rooms: no_of_rooms,
        }
      );
      setDetails(response.data);
      if (response.data["room_count"] >= no_of_rooms) {
        if (response.data["villaAvailable"] === villaNeeded) {
          console.log("Available");
          setCurrent(current + 1);
        } else if (
          response.data["villaAvailable"] === true &&
          villaNeeded === false
        ) {
          setCurrent(current + 1);
        } else {
          message.error("Villa not available");
        }
      } else {
        message.error("Not available");
      }
    } catch (e) {
      console.log(e);
      message.error("Server not responding")
    }
  };
  return (
    <Layout>
      <Content style={{ padding: "50px", height: "100%" }}>
        <Fade>
          <Row>
            <Col span={2} />
            <Col span={20}>
              <Steps current={current}>
                <Step key="booking" title="Check Availability" />
                <Step key="bill" title="Billing Information" />
                <Step key="payment" title="Payment" />
              </Steps>
              <br></br>
              {current === 0 && (
                <>
                  <Row>
                    <Card
                      style={{
                        width: "100%",
                        boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <h1 style={{ fontSize: "34px", marginBottom: ".1em" }}>
                        {" "}
                        <RiCalendarTodoFill
                          style={{ marginTop: "1%", color: "#169120" }}
                        />{" "}
                        Booking Details
                      </h1>
                      <div
                        style={{
                          height: "4px",
                          backgroundColor: "#169120",
                          width: "5%",
                        }}
                      />
                      <br></br>
                      <h3>Booking Dates</h3>
                      <RangePicker
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        size={"large"}
                        onChange={onChangeDate}
                        placeholder={["Check In", "Check Out"]}
                        separator="to "
                        value={range}
                      ></RangePicker>
                    </Card>
                  </Row>
                  {roomList !== null ? (
                    <>
                      {roomList.map((room, index) => (
                        <Row
                          key={index}
                          style={{
                            margin: "1% 0",
                            width: "100%",
                            boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)",
                            backgroundColor: "white",
                          }}
                        >
                          <Col span={6}>
                            {room.roomType === "The Villa" ? (
                              <img loading='lazy' 
                                src="image1.jpeg"
                                style={{ margin: "" }}
                                alt="Villa Pic"
                                width="100%"
                              />
                            ) : (
                              <img loading='lazy' 
                                src="HomePage/image3.jpg"
                                alt="Room Pic"
                                style={{ margin: "" }}
                                width="100%"
                              />
                            )}
                          </Col>
                          <Col span={18}>
                            <Select
                              value={room.roomType}
                              style={{
                                width: "5 0%",
                                margin: "2%",
                                fontSize: "40px",
                              }}
                              bordered={false}
                              placeholder="Select Room Type"
                              onSelect={(e) => {
                                handleSelect(e, index, "roomType");
                              }}
                            >
                              <Select.Option value="Deluxe Room">
                                Deluxe Room
                              </Select.Option>
                              {villaNeeded === false && (
                                <Select.Option value="The Villa">
                                  The Villa
                                </Select.Option>
                              )}
                            </Select>
                            <Button
                              type="primary"
                              style={{ float: "right", margin: "2%" }}
                              onClick={() => {
                                handleDeleteRoom(index);
                              }}
                              danger
                            >
                              X Remove Room
                            </Button>
                            <Divider style={{ margin: "0% auto 1.6% auto" }} />
                            <div style={{ padding: "0 3%" }}>
                              <Row>
                                <Col span={11}>
                                  {room.roomType === "The Villa" ? (
                                    <>
                                      <h3 style={{ fontSize: "20px" }}>
                                        Villa Details
                                      </h3>
                                      <ul>
                                        <li>
                                          Four A/C Rooms with attached bathrooms
                                        </li>
                                        <li>Private Swimming Pool</li>
                                        <li>
                                          Large Living Space and Premises for
                                          spending your quality time
                                        </li>
                                        <li>
                                          Great for big families or a big group.
                                        </li>
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <h3 style={{ fontSize: "20px" }}>
                                        Room Details
                                      </h3>
                                      <ul>
                                        <li>
                                          A/C Room with attached bathrooms
                                        </li>
                                        <li>Common Swimming Pool</li>
                                        <li>Farm and Vineyard Views</li>
                                      </ul>
                                    </>
                                  )}
                                </Col>
                                <Col span={1}>
                                  <Divider
                                    type="vertical"
                                    style={{ height: "100%" }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <h3 style={{ fontSize: "20px" }}>
                                    Resident Details
                                  </h3>
                                  <ul type="none" style={{ paddingLeft: "0%" }}>
                                    <li>
                                      <Select
                                        name="numberOfAdults"
                                        style={{
                                          width: "50%",
                                          margin: "0 0 1% 0 ",
                                        }}
                                        value={room.numberOfAdults}
                                        placeholder="Number of Adults"
                                        onSelect={(e) => {
                                          handleSelect(
                                            e,
                                            index,
                                            "numberOfAdults"
                                          );
                                        }}
                                      >
                                        {room.roomType === "The Villa" ? (
                                          <>
                                            <Select.Option value={8}>
                                              8
                                            </Select.Option>
                                            <Select.Option value={9}>
                                              9
                                            </Select.Option>
                                            <Select.Option value={10}>
                                              10
                                            </Select.Option>
                                            <Select.Option value={11}>
                                              11
                                            </Select.Option>
                                            <Select.Option value={12}>
                                              12
                                            </Select.Option>
                                          </>
                                        ) : (
                                          <>
                                            <Select.Option value={1}>
                                              1
                                            </Select.Option>
                                            <Select.Option value={2}>
                                              2
                                            </Select.Option>
                                          </>
                                        )}
                                      </Select>
                                      <b
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </b>
                                    </li>
                                    <li>
                                      {room.roomType === "Deluxe Room" &&
                                      room.numberOfAdults === "3" ? (
                                        <Select
                                          disabled
                                          style={{
                                            width: "10%",
                                            margin: "0 1% 0 0 ",
                                          }}
                                          placeholder="Number of Kids"
                                        ></Select>
                                      ) : (
                                        <Select
                                          name="numberOfKids"
                                          value={room.numberOfKids}
                                          style={{
                                            width: "50%",
                                            margin: "0 1% 0 0 ",
                                          }}
                                          placeholder="Number of Kids"
                                          onSelect={(e) => {
                                            handleSelect(
                                              e,
                                              index,
                                              "numberOfKids"
                                            );
                                          }}
                                        >
                                          {room.roomType === "The Villa" ? (
                                            <>
                                              <Select.Option value={0}>
                                                0
                                              </Select.Option>
                                              <Select.Option value={1}>
                                                1
                                              </Select.Option>
                                              <Select.Option value={2}>
                                                2
                                              </Select.Option>
                                              <Select.Option value={3}>
                                                3
                                              </Select.Option>
                                              <Select.Option value={4}>
                                                4
                                              </Select.Option>
                                            </>
                                          ) : (
                                            <>
                                              <Select.Option value={0}>
                                                0
                                              </Select.Option>
                                              <Select.Option value={1}>
                                                1
                                              </Select.Option>
                                            </>
                                          )}
                                        </Select>
                                      )}
                                      <b
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                        }}
                                      >
                                        *
                                      </b>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          {room.roomType === "Deluxe Room" &&
                            room.numberOfAdults === "3" && (
                              <Row>
                                <b>
                                  <i>
                                    You are going to need an extra matress which
                                    will be provided.
                                  </i>
                                </b>
                              </Row>
                            )}
                          {room.roomType === "The Villa" &&
                            parseInt(room.numberOfAdults, 10) > 8 && (
                              <Row>
                                <b>
                                  <i>
                                    You are going to need{" "}
                                    {room.numberOfAdults - 8} extra matresses
                                    which will be provided.
                                  </i>
                                </b>
                              </Row>
                            )}
                        </Row>
                      ))}
                    </>
                  ) : (
                    <br></br>
                  )}
                  <Row>
                    <Button
                      style={{ width: "100%", margin: "1% 0", height: "3em" }}
                      size="large"
                      type="primary"
                      onClick={handleAddRoom}
                    >
                      <p style={{ fontSize: "1.5em", textAlign: "center" }}>
                        <PlusSquareOutlined /> Add Room
                      </p>
                    </Button>
                  </Row>

                  <Card
                    style={{ boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)" }}
                  >
                    <h1 style={{ fontSize: "34px", marginBottom: ".1em" }}>
                      {" "}
                      {<IoFastFood style={{ color: "#169120" }}></IoFastFood>}
                      Meal Plan
                      <b style={{ color: "red", fontSize: "20px" }}> *</b>
                    </h1>

                    <div
                      style={{
                        height: "4px",
                        backgroundColor: "#169120",
                        width: "5%",
                      }}
                    />
                    <br></br>
                    <Select
                      style={{ width: "30%", margin: "0 1% 0 0" }}
                      placeholder="Number of Vegeterians"
                      onSelect={(e) => {
                        handleMealSelect(e, "Veg");
                      }}
                    >
                      {optionList.map((item) => item)}
                    </Select>
                    <Select
                      style={{ width: "30%", margin: "0 1%" }}
                      placeholder="Number of Jain Vegeterians"
                      onSelect={(e) => {
                        handleMealSelect(e, "Jain");
                      }}
                    >
                      {optionList.map((item) => item)}
                    </Select>
                    <Select
                      style={{ width: "30%", margin: "0 1%" }}
                      placeholder="Number of Non-Vegeterians"
                      onSelect={(e) => {
                        handleMealSelect(e, "Non Veg");
                      }}
                    >
                      {optionList.map((item) => item)}
                    </Select>
                    {vegiterians + jainterians + nonvegiterians !==
                      noOfAdults + noOfKids && (
                      <p>
                        <b>Please Enter the right number of people here.</b>
                      </p>
                    )}
                  </Card>
                  <br></br>
                  <Row>
                    <Card
                      style={{ boxShadow: "0 11px 12px 0 rgba(0, 0, 0, 0.05)" }}
                    >
                      <h1 style={{ fontSize: "34px", marginBottom: ".1em" }}>
                        {" "}
                        {<IoNewspaperOutline style={{ color: "#169120" }} />}
                        Terms and Conditions
                        <b style={{ color: "red", fontSize: "20px" }}> *</b>
                      </h1>
                      <div
                        style={{
                          height: "4px",
                          backgroundColor: "#169120",
                          width: "5%",
                        }}
                      />
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Praesent et tincidunt velit. Mauris augue lacus, commodo
                        eget aliquam ac, mollis nec elit. Vestibulum ac tempor
                        arcu. Praesent in tempor ex. Praesent tristique egestas
                        libero, et sagittis massa tincidunt vitae. Cras ex urna,
                        ultrices quis pulvinar quis, interdum eget felis.
                        Integer suscipit volutpat neque vel blandit. Quisque
                        condimentum accumsan magna, non tempor arcu lobortis
                        quis. Mauris varius maximus risus sit amet faucibus.
                        Morbi ac erat sit amet justo pharetra congue. Etiam
                        feugiat erat ac ullamcorper suscipit. Suspendisse
                        pretium tincidunt odio at placerat. Proin luctus
                        fermentum elementum. Morbi orci dui, cursus eu
                        vestibulum auctor, interdum eu odio. Donec lobortis,
                        nunc gravida rutrum aliquam, felis ipsum hendrerit nisl,
                        semper dapibus est dui vitae augue. Pellentesque vitae
                        lorem laoreet, scelerisque quam et, tristique orci.
                        Vivamus vitae rutrum arcu. Nullam tincidunt sem mattis,
                        hendrerit lacus quis, feugiat lacus. Ut convallis et
                        erat sit amet placerat. In hac habitasse platea
                        dictumst. Phasellus dictum orci at turpis ullamcorper,
                        non molestie dolor convallis. Aliquam id lorem quis odio
                        vehicula viverra at vel neque. Praesent interdum
                        dignissim ante, et commodo urna accumsan ac. Donec eget
                        lorem id elit ultrices viverra. Morbi feugiat justo
                        gravida egestas laoreet. Nulla urna tortor, interdum non
                        nibh sit amet, pharetra viverra nibh. Aliquam nec augue
                        vitae libero fringilla pretium id et nisl. Phasellus sed
                        finibus justo. Aenean fermentum ut felis at pulvinar.
                        Proin non lacus id eros eleifend tempus quis vitae
                        felis. Duis dictum molestie ligula et sagittis. Donec
                        cursus risus semper ipsum rutrum mollis. Nullam euismod,
                        quam nec semper vestibulum, est dolor dictum lectus,
                        porta consectetur nunc sapien non velit. Nulla sed sem
                        nibh. Integer faucibus tempus efficitur. Aenean
                        vulputate quam odio, id finibus nulla gravida vitae.
                        Cras varius neque varius ante convallis, vel laoreet
                        nulla rhoncus. Praesent vestibulum tristique est id
                        dapibus. Fusce ante lacus, efficitur vitae dolor ut,
                        vehicula cursus tellus. Fusce pretium eu dui a
                        ultricies. Curabitur diam lorem, commodo a est nec,
                        blandit bibendum enim. Donec nec pellentesque velit.
                        Pellentesque interdum vel nisi nec pharetra. Quisque in
                        ante felis. Ut placerat, turpis eu lobortis aliquam,
                        diam mauris suscipit diam, nec tristique turpis elit vel
                        felis. Quisque viverra enim a erat feugiat euismod.
                      </p>
                    </Card>
                  </Row>
                  <Checkbox
                    name="termsAndConditions"
                    style={{ margin: ".5%" }}
                    checked={termsAndConditions}
                    onChange={onChange}
                  >
                    I have read the Terms and Conditions and Accept them.
                  </Checkbox>
                  <Button
                    type="primary"
                    size="large"
                    style={{ float: "right", margin: ".5%" }}
                    onClick={() => onClickBook(range)}
                  >
                    Check if available
                  </Button>
                </>
              )}
              {current === 5 && (
                <>
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                </>
              )}
              {current === 1 && (
                <>
                  <BillDetails
                    details={details}
                    booking={booking}
                    customer={customer}
                    prev={prev}
                    onSubmit={onSubmitCustomer}
                  />
                </>
              )}
              {current === 2 && (
                <>
                  <Button type="primary">PAY</Button>
                </>
              )}
            </Col>
            <Col span={2} />
          </Row>
        </Fade>
      </Content>
    </Layout>
  );
}

export default Booking;

import {
  Input,
  Row,
  Col,
  message,
  Button,
  Divider,
  Collapse,
  Form,
} from "antd";
import React from "react";
import {
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import "./BillDetails.css";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import {useHistory} from 'react-router-dom'
const { REACT_APP_DJANGO_URL } = process.env;


function BillDetails(props) {
  const history = useHistory()
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 786px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 786px)" });

  //TODO: Polish Customer Details Form
  const validateMessages = {
    required: "This is a required Field",
    types: {
      email: "It is not a valid email!",
    },
    number: {
      // eslint-disable-next-line
      range: "Must be between ${min} and ${max}",
    },
  };

  const onFinish = (e) => {
    console.log(e);
    let temp = props.booking;
    delete temp["range"];
    delete temp["termsAndConditions"];
    // console.log(in_dateString);
    // console.log(temp);
    // let varsd = {...temp,'check_in':in_dateString,'check_out':out_dateString}
    // console.log(varsd);
    // //YYYY-MM-DD
    MakeRequests(e, temp, temp["check_in"], temp["check_out"]);
  };
  const MakeRequests = async (
    customer,
    temp,
    in_dateString,
    out_dateString
  ) => {
    try {
      const customerRes = await axios.post(
        `https://${process.env.REACT_APP_DJANGO_URL}/customer/create/`,
        {
          name: customer.name,
          email: customer.email,
          phone: parseInt(customer.phone),
          address: customer.address,
        }
      );
      console.log("Created Custeomer");
      localStorage.setItem("customerID", customerRes.data.id);

      const booking = await axios.post(`https://${process.env.REACT_APP_DJANGO_URL}/book/`, {
        booking: {
          ...temp,
          check_in: in_dateString,
          check_out: out_dateString,
        },
        customer: customerRes.data.id,
      });
      localStorage.setItem("bookingID", booking.data.id);
      console.log("Created Booking");
      const payment = await axios.post(`https://${process.env.REACT_APP_DJANGO_URL}/payment/pay/`, {
        amount: booking.data.amount,
        made_by: customerRes.data.id,
        order: booking.data.id,
      });
      localStorage.setItem("transactionID", payment.data.id);
      const url = payment.data.redirect_url;
      window.location.href = url;
    } catch {
      message.error("Error while booking. Try sometime later");
      localStorage.removeItem("transactionID");
      localStorage.removeItem("bookingID");
      localStorage.removeItem("customerID");
      history.push("/booking")
    }
  };
  const style = {
    margin: " 1% 0",
    borderWidth: "0 0 3px 0",
    borderRight: "0px",
  };
  return (
    <>
      {props.booking.range != null && (
        <Row style={{ backgroundColor: "white" }}>
          <Col
            md={24}
            lg={12}
            style={{ backgroundColor: "#f7fbf9", padding: "3%" }}
          >
            {isDesktopOrLaptop && (
              <>
                <h1 style={{ fontSize: "400%", marginBottom: ".1em" }}>
                  Summary
                </h1>
                <div
                  style={{
                    height: "4px",
                    backgroundColor: "#169120",
                    width: "20%",
                  }}
                />
                <br />
                <Row>
                  {props.booking.range && (
                    <Col span={11}>
                      <p style={{ fontSize: "20px" }}>
                        Check In:{" "}
                        {props.booking.range[0]
                          .format("MMMM Do YYYY")
                          .toString()}
                      </p>
                    </Col>
                  )}
                  <Col span={1}>
                    <Divider
                      type="vertical"
                      style={{ backgroundColor: "darkgrey", height: "30px" }}
                    />
                  </Col>
                  {props.booking.range && (
                    <Col span={12}>
                      <p style={{ fontSize: "20px" }}>
                        Check Out:{" "}
                        {props.booking.range[1]
                          .format("MMMM Do YYYY")
                          .toString()}{" "}
                        {}
                      </p>
                    </Col>
                  )}
                </Row>
                <Collapse bordered={false}>
                  <Collapse.Panel
                    header={
                      <Row>
                        <Col span={18}>
                          <p style={{ fontSize: "20px", margin: "1px" }}>
                            Price (
                            {props.booking.no_of_rooms > 0 && (
                              <>
                                {props.booking.no_of_rooms} Room
                                {props.booking.no_of_rooms > 1 && <>s</>}
                              </>
                            )}{" "}
                            {props.booking.no_of_rooms > 0 &&
                              props.booking.isVilla && <>+</>}{" "}
                            {props.booking.isVilla && <>The Villa</>} x{" "}
                            {props.booking.range[1].diff(
                              props.booking.range[0],
                              "days"
                            )}{" "}
                            Nights)
                          </p>
                        </Col>
                        <Col span={6}>
                          <p
                            style={{
                              float: "right",
                              fontSize: "25px",
                              margin: "1px",
                            }}
                          >
                            ₹{props.details.amount}
                          </p>
                        </Col>
                      </Row>
                    }
                    extra={<p style={{ fontSize: "26px", margin: "1px" }}></p>}
                  >
                    {props.booking.no_of_rooms > 0 && (
                      <>
                        {props.details.no_of_weekdays > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            A Room per week day:{" "}
                            {props.details.room_weekdayRate}
                          </h3>
                        )}
                        {props.details.no_of_weekend > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            A Room per week-end day:{" "}
                            {props.details.room_weekendRate}
                          </h3>
                        )}
                        {props.details.special_room_rate !== {} &&
                          Object.keys(props.details.special_room_rate).map(
                            (item, index) => (
                              <h3
                                style={{ fontSize: "18px", paddingLeft: "6%" }}
                              >
                                Special Day Rates [
                                {props.details.special_room_rate[item]} days]:{" "}
                                {item}
                              </h3>
                            )
                          )}
                      </>
                    )}
                    {props.booking.isVilla && (
                      <>
                        {props.details.no_of_weekdays > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            Villa per week day:{" "}
                            {props.details.villa_weekdayRate}
                          </h3>
                        )}
                        {props.details.no_of_weekend > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            Villa per week-end day:{" "}
                            {props.details.villa_weekendRate}
                          </h3>
                        )}
                        {props.details.special_villa_rate !== {} &&
                          Object.keys(props.details.special_villa_rate).map(
                            (item, index) => (
                              <h3
                                style={{ fontSize: "18px", paddingLeft: "6%" }}
                              >
                                Special Day Rates [
                                {props.details.special_villa_rate[item]} days]:{" "}
                                {item}
                              </h3>
                            )
                          )}
                      </>
                    )}
                  </Collapse.Panel>

                  {/* <Collapse.Panel header={
                                    <Row>
                                        <Col span={12}>
                                            <p style={{ fontSize: '20px', margin: '1px' }}>Meal Price ({props.booking.num_of_adults} Adult{props.booking.num_of_adults > 1 && <>s</>} + {props.booking.num_of_child} Kid{props.booking.num_of_child > 1 && <>s</>}) x {props.booking.range[1].diff(props.booking.range[0], 'days')} Nights</p>
                                        </Col>
                                        <Col span={12}>
                                            <p style={{ float: 'right', fontSize: '25px', margin: '1px' }}>₹3,000</p>
                                        </Col>
                                    </Row>} extra={<p style={{ fontSize: '26px', margin: '1px' }}></p>}>
                                    <h3 style={{ fontSize: '18px', paddingLeft: '6%' }}>Adult Meal Per Day: ₹1,200</h3>
                                    <h3 style={{ fontSize: '18px', paddingLeft: '6%' }}>Kid Meal Per Day: ₹800</h3>
                                </Collapse.Panel> */}
                  <Collapse.Panel
                    header={
                      <Row>
                        <Col span={12}>
                          <p style={{ fontSize: "20px", margin: "1px" }}>Tax</p>
                        </Col>
                        <Col span={12}>
                          <p
                            style={{
                              float: "right",
                              fontSize: "25px",
                              margin: "1px",
                            }}
                          >
                            ₹{props.details.gst_amount - props.details.amount}
                          </p>
                        </Col>
                      </Row>
                    }
                    extra={<p style={{ fontSize: "26px", margin: "1px" }}></p>}
                  >
                    <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                      CGST 9%: ₹
                      {(props.details.gst_amount - props.details.amount) / 2}
                    </h3>
                    <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                      SGST 9%: ₹
                      {(props.details.gst_amount - props.details.amount) / 2}
                    </h3>
                  </Collapse.Panel>
                </Collapse>
                <Row style={{ padding: "0 2%" }}>
                  <Col span={12}>
                    <p style={{ fontSize: "23px", margin: "1px" }}>
                      <b>Total</b>
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      style={{
                        float: "right",
                        fontSize: "28px",
                        margin: "1px",
                      }}
                    >
                      <b>₹{props.details.gst_amount}</b>
                    </p>
                  </Col>
                </Row>
              </>
            )}
            {isTabletOrMobile && (
              <div>
                <h3 style={{ fontSize: "1.7em", marginBottom: ".1em" }}>
                  Summary
                </h3>
                <div
                  style={{
                    height: "4px",
                    backgroundColor: "#169120",
                    width: "20%",
                  }}
                />
                <br />

                <Row>
                  <Col span={11}>
                    <p style={{ fontSize: "16px" }}>
                      Check In:{" "}
                      {props.booking.range[0].format("MMMM Do YYYY").toString()}
                    </p>
                  </Col>
                  <Col span={1}>
                    <Divider
                      type="vertical"
                      style={{ backgroundColor: "darkgrey", height: "30px" }}
                    />
                  </Col>
                  <Col span={12}>
                    <p style={{ fontSize: "16px" }}>
                      Check Out:{" "}
                      {props.booking.range[1].format("MMMM Do YYYY").toString()}{" "}
                      {}
                    </p>
                  </Col>
                </Row>
                <Collapse bordered={false}>
                  <Collapse.Panel
                    header={
                      <Row>
                        <Col span={18}>
                          <p style={{ fontSize: "16px", margin: "1px" }}>
                            Price (
                            {props.booking.no_of_rooms > 0 && (
                              <>
                                {props.booking.no_of_rooms} Room
                                {props.booking.no_of_rooms > 1 && <>s</>}
                              </>
                            )}{" "}
                            {props.booking.no_of_rooms > 0 &&
                              props.booking.isVilla && <>+</>}{" "}
                            {props.booking.isVilla && <>The Villa</>} x{" "}
                            {props.booking.range[1].diff(
                              props.booking.range[0],
                              "days"
                            )}{" "}
                            Nights)
                          </p>
                        </Col>
                        <Col span={6}>
                          <p
                            style={{
                              float: "right",
                              fontSize: "18px",
                              margin: "1px",
                            }}
                          >
                            ₹10,000
                          </p>
                        </Col>
                      </Row>
                    }
                  >
                    {props.booking.no_of_rooms > 0 && (
                      <>
                        {props.details.no_of_weekdays > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            A Room per week day:{" "}
                            {props.details.room_weekdayRate}
                          </h3>
                        )}
                        {props.details.no_of_weekend > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            A Room per week-end day:{" "}
                            {props.details.room_weekendRate}
                          </h3>
                        )}
                        {props.details.special_room_rate !== {} &&
                          Object.keys(props.details.special_room_rate).map(
                            (item, index) => (
                              <h3
                                style={{ fontSize: "18px", paddingLeft: "6%" }}
                              >
                                Special Day Rates [
                                {props.details.special_room_rate[item]} days]:{" "}
                                {item}
                              </h3>
                            )
                          )}
                      </>
                    )}
                    {props.booking.isVilla && (
                      <>
                        {props.details.no_of_weekdays > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            Villa per week day:{" "}
                            {props.details.villa_weekdayRate}
                          </h3>
                        )}
                        {props.details.no_of_weekend > 0 && (
                          <h3 style={{ fontSize: "18px", paddingLeft: "6%" }}>
                            Villa per week-end day:{" "}
                            {props.details.villa_weekendRate}
                          </h3>
                        )}
                        {props.details.special_villa_rate !== {} &&
                          Object.keys(props.details.special_villa_rate).map(
                            (item, index) => (
                              <h3
                                style={{ fontSize: "18px", paddingLeft: "6%" }}
                              >
                                Special Day Rates [
                                {props.details.special_villa_rate[item]} days]:{" "}
                                {item}
                              </h3>
                            )
                          )}
                      </>
                    )}
                  </Collapse.Panel>

                  {/* <Collapse.Panel header={
                                    <Row>
                                        <Col span={12}>
                                            <p style={{ fontSize: '16px', margin: '1px' }}>Meal Price ({props.booking.noOfAdults} Adult{props.booking.noOfAdults > 1 && <>s</>} + {props.booking.noOfKids} Kid{props.booking.noOfKids > 1 && <>s</>})</p>
                                        </Col>
                                        <Col span={12}>
                                            <p style={{ float: 'right', fontSize: '18px', margin: '1px' }}>₹3,000</p>
                                        </Col>
                                    </Row>} >
                                    <h3 style={{ fontSize: '14px', paddingLeft: '6%' }}>Adult Meal Per Day: ₹1,200</h3>
                                    <h3 style={{ fontSize: '14px', paddingLeft: '6%' }}>Kid Meal Per Day: ₹800</h3>
                                </Collapse.Panel> */}
                  <Collapse.Panel
                    header={
                      <Row>
                        <Col span={12}>
                          <p style={{ fontSize: "16px", margin: "1px" }}>Tax</p>
                        </Col>
                        <Col span={12}>
                          <p
                            style={{
                              float: "right",
                              fontSize: "18px",
                              margin: "1px",
                            }}
                          >
                            ₹6,000
                          </p>
                        </Col>
                      </Row>
                    }
                  >
                    <h3 style={{ fontSize: "14px", paddingLeft: "6%" }}>
                      CGST 9%: ₹3000
                    </h3>
                    <h3 style={{ fontSize: "14px", paddingLeft: "6%" }}>
                      SGST 9%: ₹3000
                    </h3>
                  </Collapse.Panel>
                </Collapse>
                <Row style={{ padding: "0 2%" }}>
                  <Col span={12}>
                    <p style={{ fontSize: "20px", margin: "1px" }}>
                      <b>Total</b>
                    </p>
                  </Col>
                  <Col span={12}>
                    <p
                      style={{
                        float: "right",
                        fontSize: "23px",
                        margin: "1px",
                      }}
                    >
                      <b>₹16,000</b>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
          <Col md={24} lg={12} style={{ padding: "3%" }}>
            {isDesktopOrLaptop && (
              <h1 style={{ fontSize: "400%", marginBottom: ".1em" }}>
                Billing Details
              </h1>
            )}
            {isTabletOrMobile && (
              <h3 style={{ fontSize: "1.7em", marginBottom: ".1em" }}>
                Billing Details
              </h3>
            )}
            <div
              style={{
                height: "4px",
                backgroundColor: "#169120",
                width: "20%",
              }}
            ></div>
            <br />
            <Form
              onFinish={onFinish}
              onFinishFailed={() => {
                message.error("Error");
              }}
              validateMessages={validateMessages}
            >
              <Form.Item
                value={props.customer.name}
                name="name"
                rules={[{ required: true }]}
              >
                <Input
                  style={style}
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="Customer Name"
                ></Input>
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[{ min: 10, max: 10, required: true }]}
              >
                <Input
                  style={style}
                  size="large"
                  prefix={<PhoneOutlined />}
                  placeholder="Phone Number"
                ></Input>
              </Form.Item>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input
                  style={style}
                  size="large"
                  prefix={<MailOutlined />}
                  placeholder="Email Address"
                ></Input>
              </Form.Item>
              <Form.Item name="address" rules={[{ required: true }]}>
                <Input
                  style={style}
                  size="large"
                  prefix={<HomeOutlined />}
                  placeholder="Residential Address"
                ></Input>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Pay
              </Button>
              <Button style={{ margin: "0 8px" }} onClick={() => props.prev()}>
                Previous
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </>
  );
}

export default BillDetails;

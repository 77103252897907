import { Card, Col, Rate, Row, List } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import React,{useEffect} from "react";
import "./About.css";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";
import { MdRoomService } from "react-icons/md";
import {
  FaSwimmer,
  FaBicycle,
  FaSnowflake,
  FaParking,
  FaTv,
} from "react-icons/fa";
import { GiCricketBat, GiFarmTractor } from "react-icons/gi";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  const amenities = [
    {
      icon: <FaSnowflake />,
      title: "A/C Deluxe Rooms",
      description: "Temperature Controlled Luxurious Rooms are available.",
    },
    {
      icon: <FaParking />,
      title: "Parking Space",
      description:
        "Spacious parking space for your vehicles is present for you to be at ease.",
    },
    {
      icon: <FaTv />,
      title: "Television",
      description:
        "TVs are present in every room so you never miss out your entertainment. ",
    },
    {
      icon: <MdRoomService />,
      title: "Room Service",
      description: "Our quick, prompt and delicious room service is available.",
    },
    {
      icon: <GiFarmTractor />,
      title: "Pure Farm Experience",
      description:
        "Enjoy the farm experience in Guava plantations and famous Nashik vineyards.",
    },
    {
      icon: <GiCricketBat />,
      title: "Variety of Sports",
      description:
        "Play games like Cricket, Badminton, Table Tennis, Carrom, Basketball, Football and many more to have blast with your friends and family.",
    },
    {
      icon: <FaBicycle />,
      title: "Bicycles for every age group",
      description:
        "Take a ride to accross our farmland to vapourize your urban stress and tensions.",
    },
    {
      icon: <FaSwimmer />,
      title: "Swimming Pool",
      description:
        "Enjoy swimming in our clean and always filtered swimming pools.",
    },
  ];
  return (
    <Fade>
      <Layout>
        <Content>
          <Row style={{ height: 330 }} className="about">
            <Col lg={3} />
            <Col lg={6}>
              <h2
                style={{
                  marginTop: "40% ",
                  marginLeft: "15%",
                  color: "white",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                About Us
              </h2>
            </Col>
          </Row>

          <Row style={{ backgroundColor: "white" }}>
            <Col lg={3} />
            <Col lg={18} style={{ border: "0px" }}>
              <Fade bottom>
                <Row style={{ padding: "2% 5%" }}>
                  <Col
                    lg={12}
                    style={{
                      paddingLeft: "2%",
                      paddingTop: "3%",
                      paddingRight: "2%",
                    }}
                  >
                    <h2>Our Promise to Quality</h2>
                    <h3>
                      We have dedicated ourselves to provide the best experience
                      you can have in Nashik and touch our customers hearts. We
                      have over 100+ 5 star reviews on sites like Google and
                      AirBNB.
                    </h3>
                    <img loading='lazy' 
                      src="https://img.icons8.com/color/48/000000/google-logo.png"
                      alt="google logo"
                    />
                    <Rate
                      style={{ marginLeft: "1%" }}
                      disabled
                      defaultValue={5}
                    />
                    <img loading='lazy' 
                      style={{ marginLeft: "5%" }}
                      src="https://img.icons8.com/color/48/000000/airbnb.png"
                      alt="airbnb logo"
                    />
                    <Rate
                      style={{ marginLeft: "1%" }}
                      disabled
                      defaultValue={5}
                    />
                  </Col>
                  <Col lg={12}>
                    <img loading='lazy' 
                      src="/About/image11.jpg"
                      style={{ margin: "auto", width: "100%" }}
                      alt="villa"
                    />
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col lg={3} />
          </Row>
          <Row>
            <Col lg={3} />
            <Col lg={18} style={{ border: "0px" }}>
              <Fade bottom>
                <Row style={{ padding: "2% 5%" }}>
                  <Col lg={10}>
                    <Slider dots={true} autoplay={true} autoplaySpeed={1600}>
                      <img loading='lazy' 
                        src="/About/space1.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/space2.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/space3.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                    </Slider>
                  </Col>
                  <Col
                    lg={14}
                    style={{
                      paddingLeft: "2%",
                      paddingTop: "3%",
                      paddingLeft: "4%",
                    }}
                  >
                    <h2>The Space</h2>
                    <h3>
                      With 4 acres of green farms to explore, you can have a
                      blast with your family or friends on playing
                      cricket,badminton,football,volleyball on the lawn , get
                      nostalgic swinging the swings or chill in the swimming
                      pool.
                    </h3>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col lg={3} />
          </Row>
          <Row style={{ backgroundColor: "white" }}>
            <Col lg={3} />
            <Col lg={18} style={{ border: "0px" }}>
              <Fade bottom>
                <Row style={{ padding: "2% 5%" }}>
                  <Col
                    lg={16}
                    style={{
                      paddingLeft: "2%",
                      paddingTop: "3%",
                      paddingRight: "2%",
                    }}
                  >
                    <h2>Cleanliness</h2>
                    <h3>
                      Our promise is to make sure you have the best experience
                      and cleanliness plays a big factor into it. We ensure that
                      during your stay, your room is sanitized and cleaned for
                      you to stay worry free.
                    </h3>
                  </Col>
                  <Col lg={8}>
                    <img loading='lazy' 
                      src="/About/toilet.jpg"
                      style={{ height: "400px" }}
                      alt="villa"
                    />
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col lg={3} />
          </Row>
          <Row>
            <Col lg={3} />
            <Col lg={18} style={{ border: "0px" }}>
              <Fade bottom>
                <Row style={{ padding: "2% 5%" }}>
                  <Col lg={10}>
                    <Slider dots={true} autoplay={true} autoplaySpeed={1600}>
                      <img loading='lazy' 
                        src="/About/dining1.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/dining2.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/dining3.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                    </Slider>
                  </Col>
                  <Col
                    lg={14}
                    style={{
                      paddingLeft: "2%",
                      paddingTop: "3%",
                      paddingLeft: "4%",
                    }}
                  >
                    <h2>Dining</h2>
                    <h3>
                      Equipped with a big kitchen, we offer one of the best
                      tastes in traditional Maharashtrian Food which you just
                      can't get enough of. Contact us to know more about it.
                    </h3>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col lg={3} />
          </Row>
          <Row style={{ backgroundColor: "white" }}>
            <Col lg={3} />
            <Col lg={18} style={{ border: "0px" }}>
              <Fade bottom>
                <Row style={{ padding: "2% 5%" }}>
                  <Col
                    lg={14}
                    style={{
                      paddingLeft: "2%",
                      paddingTop: "3%",
                      paddingRight: "2%",
                    }}
                  >
                    <h2>Comfort</h2>
                    <h3>
                      We take pride in our ability to make you feel super
                      comfortable and make sure it stays that way. Even while exploring our farms, you can be relaxed and have fun with your loved ones without any worries.
                    </h3>
                  </Col>
                  <Col lg={10}>
                    <Slider
                      dots={true}
                      autoplay={true}
                      autoplaySpeed={1600}
                      style={{}}
                    >
                      <img loading='lazy' 
                        src="/About/bedroom1.jpg"
                        style={{ height: "500px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/bedroom2.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/bedroom3.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                    </Slider>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col lg={3} />
          </Row>
          <Row>
            <Col lg={3} />
            <Col lg={18} style={{ border: "0px" }}>
              <Fade bottom>
                <Row style={{ padding: "2% 5%" }}>
                  <Col lg={7} style={{ paddingTop: "3%" }}>
                    <Slider dots={true} autoplay={true} autoplaySpeed={1600}>
                      <img loading='lazy' 
                        src="/About/amenity1.jpg"
                        style={{ height: "300px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/amenity2.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/amenity3.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/amenity4.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                      <img loading='lazy' 
                        src="/About/amenity5.jpg"
                        style={{ height: "340px" }}
                        alt="villa"
                      />
                    </Slider>
                  </Col>
                  <Col lg={17} style={{ paddingLeft: "4%", paddingTop: "2%" }}>
                    <h2>
                      Amenities
                      <List
                        itemLayout="horizontal"
                        dataSource={amenities}
                        grid={{
                          gutter: 16,
                          xs: 1,
                          sm: 2,
                          md: 4,
                          lg: 4,
                          xl: 6,
                          xxl: 3,
                        }}
                        renderItem={(item) => (
                          <List.Item style={{ padding: "0" }}>
                            <Card style={{ height: "200px" }}>
                              <Row>
                                <p style={{ fontSize: "30px", margin: "0" }}>
                                  {item.icon}{" "}
                                </p>{" "}
                                <p
                                  style={{ marginTop: "3%", marginLeft: "3%" }}
                                >
                                  {item.title}
                                </p>
                              </Row>
                              <Row>
                                <h4>{item.description}</h4>
                              </Row>
                            </Card>
                          </List.Item>
                        )}
                      ></List>
                    </h2>
                  </Col>
                </Row>
              </Fade>
            </Col>
            <Col lg={3} />
          </Row>
          <Fade bottom>
            <Row style={{ padding: "2% 0", backgroundColor: "white" }}>
              <Col lg={3} />

              <Col lg={18} style={{ border: "0px" }}>
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3747.6733665624974!2d73.7504804153495!3d20.064119625381224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdde94bccc04af9%3A0xa100f1c3211d9b3e!2sThe%20Green%20Estate!5e0!3m2!1sen!2sin!4v1609058723816!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  frameborder="0"
                  style={{ borderRadius: "20px" }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </Col>

              <Col lg={3} />
            </Row>
          </Fade>
        </Content>
      </Layout>
    </Fade>
  );
}

export default About;

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Booking from './Booking';
import BookingMobile from './BookingMobile';

function Booking_Check() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 786px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 786px)' });
    return (
        <>
            {isDesktopOrLaptop && <Booking />}
            {isTabletOrMobile &&  <BookingMobile />}
        </>
    )
}

export default Booking_Check

import { Card, Table } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout';
import React from 'react'

function Inventory() {
    //TODO: Write Query to fetch Inventory
    
    const baseColoums = [
        {
            title: "Item"
        },
        {
            title: "Quantity"
        },
        {
            title: "Actions",
            render: <></>
        }
    ];
    return (
        <Layout>
            <Content style={{ padding: '50px', height: '90vh' }}>
                <Card title={<h1 style={{fontSize:'45px'}}>Inventory</h1>}>
                    <Table  columns={baseColoums}></Table>
                </Card>
            </Content>
        </Layout>
    )
}

export default Inventory

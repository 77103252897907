import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from './views/About/About';
import Inventory from './views/AdminPanel/Inventory';
import Booking_Check from './views/Booking/Booking_Check';
import Payment from './views/Booking/Payment';
import Contact from './views/Contact/Contact';
import HomePage from './views/HomePage/HomePage';

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/booking" component={Booking_Check} />
                <Route path="/contact" component={Contact} />
                <Route path="/about" component={About} />
                <Route path="/inventory" component={Inventory} />
                <Route path='/payment/callback/' component={Payment}/>
                <Route component={HomePage}/>
            </Switch>
        );

    }
}
export default Routes;
import { Row, Col } from "antd";
import React from "react";
import { SiAirbnb } from "react-icons/si";
import {
  IoLogoFacebook,
  IoLogoWhatsapp,
  IoLogoInstagram,
} from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import Fade from "react-reveal/Fade";

import terms from './terms.pdf'
import privacy from './privacy.pdf'
import cancellation from './cancellation.pdf'

function Footer() {
  const location = useLocation();
  return (
    <>
      {location.pathname.split("/")[1] === "payment" ||
      location.pathname.split("/")[1] === "booking" ? (
        <></>
      ) : (
        <Fade bottom>
          <Row style={{ height: "10em", padding: "1%", textAlign: "center" }}>
            <Col lg={3} xs={0} />
            <Col lg={6} xs={24}>
              <h3 style={{ fontSize: "180%", color: "#169120" }}>
                <b>The Green Estate Resort</b>
              </h3>
              <p>
                Ajay Murlidhar Somvanshi (HUF)  <br />
                GST No. 27AAJHA0998Q1ZJ  <br />
                Dari-Matori Road, Matori Village, Near Makmalabad, <br />
                Nashik 422003, Maharashtra.
              </p>
              <p style={{ fontSize: "200%" }}>
                <a
                  style={{ color: "#4267b2" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/people/The-Green-Estate-Resort/61556199878551/"
                >
                  <IoLogoFacebook />
                </a>
                {" "}
                <a
                  style={{ color: "#dd2a7b" }}
                  href="https://www.instagram.com/the_green_estate_resort/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IoLogoInstagram />
                </a>{" "}
                <a
                  href="https://wa.me/+919175234850"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IoLogoWhatsapp />
                </a>{" "}
              </p>
            </Col>
            <Col lg={6} xs={24}>
              <h3 style={{ fontSize: "180%" }}>Quick Links</h3>
              {/* <Link to="/booking">Book Now</Link> <br /> */}
              <Link to="/about">About Us</Link> <br />
              <Link to="/contact">Contact Us</Link>  <br />
              <a href = {terms} target = "_blank">Terms and Conditions</a> <br />
              <a href = {privacy} target = "_blank">Privacy Policy</a> <br />
              <a href = {cancellation} target = "_blank">Cancellation & Refund Policy</a>
            </Col>
            <Col lg={6} xs={24}>
              <h3 style={{ fontSize: "180%" }}>Contact Us</h3>
              <h4>Phone Number: +91 9175234850</h4>
              <h4>Email Address: reservations@thegreenestateresort.com</h4>
              <h4>Address: The Green Estate Resort, Dari-Matori Road, Matori Village, Near Makmalabad, <br />
                Nashik 422003, Maharashtra</h4>
            </Col>
            <Col lg={3} xs={0} />
            <p style={{ width: "100%", textAlign: "center", fontSize: "120%" }}>
              Made with ❤️ by Bitglaze Technologies Pvt. Ltd.
            </p>
          </Row>
        </Fade>
      )}
    </>
  );
}

export default Footer;

import { BrowserRouter as Router } from 'react-router-dom';
import './App.css'
import Routes from "./routes";
import Navbar from './components/navbar/Header';
import Footer from './components/navbar/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes />
        <Footer/>
      </Router>
    </div>
  );
}

export default App;

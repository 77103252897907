import { Row, Col, Result, message } from "antd";
import React, { useEffect } from "react";
import queryString from "query-string";
import axios from "axios";
const { REACT_APP_DJANGO_URL } = process.env;

function Payment(props) {
  //eslint-disable-next-line
  const transaction_id = localStorage.getItem("transactionID");
  const booking_id = localStorage.getItem("bookingID");
  const customer_id = localStorage.getItem("customerID");
  useEffect(() => {
    sendPaymentDetails(transaction_id);

    //eslint-disable-next-line
  }, []);
  const details = queryString.parse(props.location.search);
  //eslint-disable-next-line
  const sendPaymentDetails = async (transaction_id) => {
    await axios.get(
      `https://${process.env.REACT_APP_DJANGO_URL}/payment/get/${details.payment_id}/${details.payment_request_id}/${transaction_id}`
    );
    await axios.get(
      `https://${process.env.REACT_APP_DJANGO_URL}/book/reciept/${booking_id}/${customer_id}/${transaction_id}/`
    );
    localStorage.removeItem("transactionID");
    localStorage.removeItem("bookingID");
    localStorage.removeItem("customerID");
  };
  return (
    <Row>
      <Col span={4} />
      <Col span={16} style={{ textAlign: "center" }}>
        {details && (
          <>
            {details.payment_status === "Credit" ? (
              <>
                {" "}
                <Result status="success" title="Booking Successful"></Result>
                <h1>
                  Thank you for booking at The Green Estate Resort! You can call us at
                  9175234850 for your booking confirmation.
                </h1>
                <h3>We have mailed you the receipt.</h3>
              </>
            ) : (
              <>
                {" "}
                <Result status="error" title="Booking Failed"></Result>
                <h3>There was some issue.</h3>
              </>
            )}
          </>
        )}
      </Col>
      <Col span={4} />
    </Row>
  );
}

export default Payment;

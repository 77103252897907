import React from "react";
import Layout, { Header } from "antd/lib/layout/layout";
import Fade from "react-reveal/Fade";
import { Menu, Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
function Navbar() {
  const location = useLocation();

  return (
    <>
      {location.pathname.split("/")[1] === "payment"  || location.pathname.split("/")[1] === "booking" ? (
        <Layout>
          <Header style={{ backgroundColor: "white" }}>
            <Fade top>
              <Row>
                <Col lg={12} xs={20}>
                  <Link to='/'><h1> <img loading='lazy'  src='logo.png' style={{height:'64px'}}/>The Green Estate Resort By Ajay Murlidhar Somvanshi (HUF)</h1></Link>
                </Col>
                <Col lg={6} xs={0} />
                <Col lg={6} xs={4} />
              </Row>
            </Fade>
          </Header>
        </Layout>
      ) : (
        <Layout>
          <Header style={{ backgroundColor: "white" }}>
            <Fade top>
              <Row>
                <Col lg={6} xs={20}>
                <Link to='/'><h1> <img loading='lazy'  src='logo.png' style={{height:'64px'}}/>The Green Estate Resort</h1></Link>
                </Col>
                <Col lg={12} xs={0} />
                <Col lg={6} xs={4}>
                  <Menu
                    mode="horizontal"
                    style={{ backgroundColor: "white", borderBottom: "0px" }}
                  >
                    <Menu.Item>
                      <Link to="/">Home</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/about">About</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/contact">Contact Us</Link>
                    </Menu.Item>
                    {/* <Menu.Item style={{ color: "green" }}>
                      {" "}
                      <Link to="/booking">BOOK NOW </Link>
                    </Menu.Item> */}
                  </Menu>
                </Col>
              </Row>
            </Fade>
          </Header>
        </Layout>
      )}
    </>
  );
}

export default Navbar;
